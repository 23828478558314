<template>
    <div class="bell-button">
        <v-popover :disabled="showNotificationBell" @hide="showNotificationBell = false">
            <div class="tooltip-target b3">
                <img
                    :src="require(`@assets/img/icons/notification-${ bellIcon[currentImportancesID] }.svg`)"
                    alt="bell"
                    class="bell" 
                >
            </div>

            <template slot="popover">
                <div class="popover-options notifications">
                    <h3 class="title">
                        Notifications
                    </h3>
                    <div
                        v-for="notification of notifications_importances"
                        :key="notification.id"
                        class="importances"
                        @click="setNotificationsImportance(notification)"
                    >
                        <span 
                            :class="{ 'active': currentImportances.importance_id === notification.id }" 
                        >
                            {{ notification.name }}
                        </span>
                    </div>
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import _isEmpty from "lodash/isEmpty";

export default {
    name: "NotificationBell",
    props: {
        currentUserData: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        notifications_importances: [],
        currentImportances: {},
        showNotificationBell: false,
        bellIcon: {
            1: "all",
            2: "normal",
            3: "off"
        }
    }),
    computed: {
        ...mapGetters({
            userData: "User/userData"
        }),
        hasNotification() {
            return !_isEmpty(this.currentImportances);
        },
        currentImportancesID() {
            // eslint-disable-next-line camelcase
            const { importance_id } = this.currentImportances;

            // eslint-disable-next-line camelcase
            return importance_id ? importance_id : 3;
        }
    },
    mounted() {
        this.getUserNotification();
    },
    methods: {
        async getUserNotification() {
            await this.getUserNotificationsImportance();
            await this.getNotificationsImportance();
            await this.checkNotificationImpotance();
        },
        async checkNotificationImpotance() {
            if (!this.hasNotification) {
                const all = this.notifications_importances.find(({ id }) => id === 1);
                this.setNotificationsImportance(all);
            }
        },
        async getNotificationsImportance() {
            const { data: NotificationsImportance } = await axios.get("/notifications_importance");
            this.notifications_importances = NotificationsImportance;
        },
        async getUserNotificationsImportance() {
            const { data: CurrentImportances } = 
                await axios.get(`/users/0/notifications_importance?q=(system_modules_id:2,entity_id:${ this.currentUserData.id })`);
            this.currentImportances = CurrentImportances[0] ? CurrentImportances[0] : { };
        },
        async setNotificationsImportance(notification) {
            this.currentImportances = { ...this.currentImportances, importance_id: notification.id };

            this.showNotificationBell = true;

            await axios.post("/users/0/notifications_importance", {
                system_modules_id: 2,
                entity_id: this.currentUserData.id,
                importance_id: notification.id
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .bell-button {
        margin-right: 10px;

        .bell {
            cursor: pointer;
            width: 40px;
        }


        @media(max-width: $md) {
            margin-top: 135px;
        }
    }

    .popover-options.notifications {
        color: #fff;
        padding: 20px;
        text-align: left;

        .title {
            font-size: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(84, 84, 88, 0.65);
        }

        .importances {
            padding: 10px 0;
            cursor: pointer;

            &:hover {
                color: #05A88F;
            }
        }

        .active {
            color: #05A88F;
        }

        /deep/ .popover-option {
            cursor: pointer;

            svg, img {
                margin-right: 5px;
            }

            &:focus {
                outline: none;
            }
        }
    }
</style>
